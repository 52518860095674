import 'firebase/compat/auth'
import 'firebase/compat/database'

import firebase from 'firebase/compat/app'

import { firebaseFunction } from '../firebase/firebaseFunction'
import { logError } from '../logging/SentryLogger'
import {
  LOGIN_SUCCESS,
  LOGOUT,
  QRCODE_EXPIRED,
  QRCODE_FETCH_FAILED,
  QRCODE_FETCH_LOADING,
  QRCODE_FETCH_SUCCESS,
  QRCODE_LOGIN_SUCCESS,
  QRCODE_SCANNED,
} from './types'

const doAnonLogin = async () => {
  const currentUser = await firebase.auth().currentUser
  if (currentUser) {
    await firebase.auth().signOut()
  }
  await firebase.auth().signInAnonymously()

  const setTempClaim = firebaseFunction().httpsCallable('setTempUserClaim')
  await setTempClaim()
}

export const doLogout = () => {
  firebase.auth().signOut()
}

export const setAuthStateListener = () => async (dispatch) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) {
      dispatch({
        type: LOGOUT,
      })

      return
    }

    const ref = await firebase
      .database()
      .ref(`/users/${user.uid}`)
      .once('value')

    if (ref.exists()) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { userId: user.uid, ...ref.val() },
      })

      return
    }

    dispatch({
      type: LOGOUT,
    })
  })
}

let codeExpiredTimeout
export const requestQrCode = () => async (dispatch) => {
  dispatch({ type: QRCODE_FETCH_LOADING })

  try {
    await doAnonLogin()
    const cloudFunction = firebaseFunction().httpsCallable(
      'generateQrLoginCode'
    )
    const res = await cloudFunction()
    if (res && res.data && res.data.code) {
      const code = res.data.code
      // listen for the scanned event
      const listener = setQrCodeScannedListener(code)(dispatch)
      // the code will expire after 60 seconds.
      codeExpiredTimeout = setTimeout(() => {
        // remove the callback
        firebase.database().ref(`qrCodes/${code}/token`).off('value', listener)
        // Remove it from state.
        dispatch({ type: QRCODE_EXPIRED })
      }, 60000)
      dispatch({ type: QRCODE_FETCH_SUCCESS, payload: code })
    } else {
      dispatch({ type: QRCODE_FETCH_FAILED })
    }
  } catch (error) {
    logError(error)
    dispatch({ type: QRCODE_FETCH_FAILED })
  }
}

const setQrCodeScannedListener = (code) => (dispatch) => {
  if (!code) {
    return
  }
  const ref = firebase.database().ref(`qrCodes/${code}`)
  // return the callback to remove it later
  return ref.child('token').on('value', async (snap) => {
    await onQrCodeScanned(snap, dispatch)
  })
}

const onQrCodeScanned = async (snap, dispatch) => {
  if (snap.exists()) {
    dispatch({ type: QRCODE_SCANNED })
    // clear the timeout for expiring code
    clearTimeout(codeExpiredTimeout)
    try {
      // get the custom token from the backend
      const token = snap.val()

      await firebase.auth().signInWithCustomToken(token)
      const userToken = await firebase.auth().currentUser.getIdTokenResult()

      if (userToken.claims.phoneUser) {
        dispatch({
          type: QRCODE_LOGIN_SUCCESS,
        })
      } else {
        dispatch({
          type: QRCODE_EXPIRED,
        })
      }
    } catch (error) {
      // if the login failed, reset the sequence by expiring te qr code.
      // so the user can try again.
      dispatch({ type: QRCODE_EXPIRED })
    }
  }
}
