import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Card } from '../components/common'
import { ACTIVE, DEACTIVATED, PAYMENT_PAST_DUE, TRIAL } from '../Constants'
import i18n from '../i18n/i18n'

const TeamDisplay = ({ team }) => {
  const getTimeStamp = (time) => {
    return moment(time).calendar(null, {
      lastDay: `[${i18n.t('datetime.yesterday_at')}] HH:mm`,
      sameDay: `[${i18n.t('datetime.today_at')}] HH:mm`,
      lastWeek: `[${i18n.t('datetime.last')}] dddd [${i18n.t(
        'datetime.at'
      )}] HH:mm`,
      nextWeek: `dddd [${i18n.t('datetime.at')}] HH:mm`,
      sameElse: `DD-MM-YYYY [${i18n.t('datetime.at')}] HH:mm`,
    })
  }

  const getTeamStatusText = () => {
    switch (team.status) {
      case ACTIVE:
        return i18n.t('team.statuses.Active')
      case TRIAL: {
        const { trialEnd, isTrialing } = team
        if (!trialEnd) {
          return i18n.t('team.statuses.PhoneNumberNotActivated')
        }
        if (!isTrialing) {
          return i18n.t('team.statuses.TrialEnded')
        }
        return i18n.t('team.statuses.Trial')
      }
      case DEACTIVATED:
        return i18n.t('team.statuses.Deactivated')
      case PAYMENT_PAST_DUE:
        return i18n.t('team.statuses.PaymentPastDue')
      default:
        return team.status
    }
  }

  return (
    <Card title={team.name || i18n.t('team.NoName')}>
      <List>
        <ListItem>
          <ListItemText primary={i18n.t('team.PhoneNumber')} />
          <ListItemSecondaryAction>{team.phoneNumber}</ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary={i18n.t('team.NumberOfTeamMembers')} />
          <ListItemSecondaryAction>
            {Object.keys(team.users || {}).length || ''}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary={i18n.t('team.Status')} />
          <ListItemSecondaryAction data-testid={'team-status-text'}>
            {getTeamStatusText()}
          </ListItemSecondaryAction>
        </ListItem>
        {team.status === TRIAL && team.trialEnd && (
          <ListItem>
            <ListItemText primary={i18n.t('team.TrialEndsOn')} />
            <ListItemSecondaryAction>
              {getTimeStamp(team.trialEnd)}
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {team.status === ACTIVE && (
          <ListItem>
            <ListItemText primary={i18n.t('team.NextPayment')} />
            <ListItemSecondaryAction>
              {team.paymentCurrentPeriodEnd}
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </Card>
  )
}

export default withRouter(TeamDisplay)
