import * as Sentry from '@sentry/browser'

import config from '../config/config.json'

export const logError = (error) => {
  if (config.env === 'test') {
    console.log(error)
  }

  Sentry.captureException(error)
}
