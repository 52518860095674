import * as Sentry from '@sentry/browser'

import { getAllTeamsForUser } from '../teams/FirebaseTeamsRepository'
import { getOrganizationById } from './FirebaseOrganizationsRepository'
import {
  ORGANIZATION_FETCHED,
  ORGANIZATION_FETCHED_FAILED,
  ORGANIZATION_FETCHING,
} from './organizationReducer'

export const fetchOrganizationsForUserAction = (user) => async (dispatch) => {
  dispatch({
    type: ORGANIZATION_FETCHING,
  })

  const teams = await getAllTeamsForUser(user)

  const organizationIds = teams
    .filter((team) => Boolean(team.organizationId))
    .reduce((orgIds, team) => {
      return {
        ...orgIds,
        [team.organizationId]: 1,
      }
    }, {})

  if (!Object.keys(organizationIds).length) {
    Sentry.captureException(
      new Error(
        'Failed to fetch organizations, user is not part of any organization'
      )
    )

    dispatch({
      type: ORGANIZATION_FETCHED_FAILED,
    })

    return
  }

  try {
    Object.keys(organizationIds).map(async (organizationId) => {
      const organization = await getOrganizationById(organizationId)

      if (!organization) {
        return
      }

      dispatch({
        type: ORGANIZATION_FETCHED,
        payload: {
          organizationId,
          ...organization,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}
