import moment from 'moment'

export const ACCOUNT_TEAM_FETCH_SUCCESS = 'account_team_fetch_success'

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACCOUNT_TEAM_FETCH_SUCCESS: {
      let paymentCurrentPeriodEnd = ''
      if (payload && payload.paymentCurrentPeriodEnd) {
        paymentCurrentPeriodEnd = moment
          .unix(payload.paymentCurrentPeriodEnd)
          .format('D MMMM YYYY')
          .toLocaleString()
      }
      const isTrialing = isTeamTrialing({
        status: payload.status,
        trialEnd: payload.trialEnd,
      })

      return {
        ...state,
        [payload.id]: { ...payload, paymentCurrentPeriodEnd, isTrialing },
      }
    }
    default:
      return state
  }
}

const isTeamTrialing = ({ status = '', trialEnd = 0 }) => {
  if (!status || !trialEnd) {
    return false
  }
  const now = moment()
  const val = status === 'trial' && moment(trialEnd).isSameOrAfter(now)
  return val
}
