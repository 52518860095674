import {
  LOGIN_FAILED,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGOUT,
  QRCODE_EXPIRED,
  QRCODE_FETCH_FAILED,
  QRCODE_FETCH_LOADING,
  QRCODE_FETCH_SUCCESS,
  QRCODE_LOGIN_SUCCESS,
  QRCODE_SCANNED,
} from './types'

const initialState = {
  error: '',
  user: null,
  qrCode: '',
  isQrCodeLoading: true,
  isQrCodeScanned: false,
  qrLoginSuccess: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return { ...state, ...initialState }
    case LOGIN_SUCCESS:
      return { ...state, error: '', user: action.payload }
    case LOGIN_FAILED:
      return { ...state, error: action.payload }
    case LOGOUT:
      return {
        ...state,
        qrCode: '',
        isQrCodeLoading: true,
        isQrCodeScanned: false,
        qrLoginSuccess: false,
      }
    case QRCODE_FETCH_SUCCESS:
      return {
        ...state,
        qrCode: action.payload,
        isQrCodeLoading: false,
        isQrCodeScanned: false,
        qrLoginSuccess: false,
      }
    case QRCODE_FETCH_FAILED:
      return {
        ...state,
        qrCode: '',
        isQrCodeLoading: false,
        isQrCodeScanned: false,
        qrLoginSuccess: false,
      }
    case QRCODE_FETCH_LOADING:
      return {
        ...state,
        isQrCodeLoading: true,
        qrCode: '',
        isQrCodeScanned: false,
        qrLoginSuccess: false,
      }
    case QRCODE_EXPIRED:
      return {
        ...state,
        qrCode: '',
        isQrCodeLoading: false,
        isQrCodeScanned: false,
        qrLoginSuccess: false,
      }
    case QRCODE_SCANNED:
      return { ...state, isQrCodeScanned: true }
    case QRCODE_LOGIN_SUCCESS:
      return { ...state, qrLoginSuccess: true }
    default:
      return state
  }
}
