import React from 'react'
import styled from 'styled-components'

import { textColor, whisperGreyColor } from '../../styles'

export default function EmptySection({ text, Icon, textTestId, imgTestId }) {
  return (
    <Container>
      <Icon
        data-testid={imgTestId}
        style={{
          color: whisperGreyColor,
          width: '100px',
          height: '100px',
        }}
      />
      <Text data-testid={textTestId}>{text}</Text>
    </Container>
  )
}

const Text = styled.div`
  font-size: 1.1rem;
  line-height: 1.3rem;
  color: ${textColor};
  text-align: center;
  width: 60%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
`
