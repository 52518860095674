import { Grid } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { isPhoneUser } from '../auth/authConditions'
import withAuthorization from '../auth/withAuthorization'
import i18n from '../i18n/i18n'
import { LOGIN } from '../routes'
import TeamDisplay from './TeamDisplay'
import { fetchTeams } from './teamsActions'

class TeamsPage extends React.Component {
  componentDidMount() {
    this.props.fetchTeams()
  }

  render() {
    return (
      <Grid container item xs={12} md={10} spacing={3}>
        {Object.values(this.props.teams)
          .sort((a, b) => {
            const nameA = a.name || i18n.t('team.NoName')
            const nameB = b.name || i18n.t('team.NoName')

            return nameA.localeCompare(nameB, { numeric: true })
          })
          .map((team) => {
            return (
              <Grid item xs={12} md={6} key={team.id}>
                <TeamDisplay team={team} />
              </Grid>
            )
          })}
      </Grid>
    )
  }
}

const mapStateToProps = ({ teams }) => {
  return { teams }
}

export default compose(
  withAuthorization({ condition: isPhoneUser, path: LOGIN }),
  connect(mapStateToProps, { fetchTeams })
)(TeamsPage)
