import * as Sentry from '@sentry/browser'
import { AppRegistry } from 'react-native'

import App from './App'
import config from './config/config.json'
import firebaseConfig from './config/firebase-config.json'
import { setConfig } from './constants/serverVariables'
import { fetchConfigInformation } from './system/ConfigBehaviour'

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.env,
})

fetchConfigInformation().then((result) => {
  firebaseConfig.databaseURL = result.databaseUrl
  firebaseConfig.cloudFunctionsUrl = result.cloudFunctionsUrl
  setConfig(result)
  AppRegistry.registerComponent('App', () => App)

  AppRegistry.runApplication('App', {
    initialProps: { firebaseConfig },
    rootTag: document.getElementById('root'),
  })
})
