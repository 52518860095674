import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

import { tiffanyBlueColor } from '../../styles'
import AnimatedCheckMark from './AnimatedCheckMark'

const useStyles = makeStyles(() => ({
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: tiffanyBlueColor,
      color: 'white',
    },
  },
}))

export default ({
  buttonText,
  onPress,
  color = 'default',
  disabled = false,
  loading = false,
  isSuccessful = false,
  size = 'medium',
  testId = '',
  variant = 'outlined',
}) => {
  const classes = useStyles()
  return (
    <Button
      onClick={onPress}
      color={color}
      disabled={disabled}
      data-testid={testId}
      variant={variant}
      size={size}
      classes={{
        ...classes,
      }}
    >
      {loading ? (
        <CircularProgress size="24px" color="inherit" />
      ) : isSuccessful ? (
        <AnimatedCheckMark />
      ) : (
        buttonText
      )}
    </Button>
  )
}
