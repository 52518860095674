import { Grid } from '@material-ui/core'
import React from 'react'

export default (props) => {
  const { children, value, index } = props

  if (value !== index) {
    return null
  }

  return (
    <Grid container spacing={3} justifyContent="flex-start">
      {children}
    </Grid>
  )
}
