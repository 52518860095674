import React from 'react'
import { Animated, Easing, StyleSheet } from 'react-native'

import logo from '../../assets/teamfone-logo.svg'

export class TeamfoneSpinner extends React.Component {
  state = {
    spinValue: new Animated.Value(0),
  }
  rotate = Animated.timing(this.state.spinValue, {
    toValue: 1,
    duration: 3000,
    easing: Easing.linear,
  })
  componentDidMount() {
    this.rotate.start(this.repeat)
  }

  repeat = () => {
    this.state.spinValue.setValue(0)
    this.rotate.start(() => {
      this.repeat()
    })
  }

  render() {
    const spin = this.state.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    })
    const { image } = styles
    return (
      <Animated.Image
        style={[image, { transform: [{ rotate: spin }] }]}
        source={logo}
      />
    )
  }
}

const styles = StyleSheet.create({
  image: {
    width: '5rem',
    height: '5rem',
  },
})
