import React from 'react'
import { IoMdClose } from 'react-icons/io'
import styled from 'styled-components'

import QrScanner from '../assets/QRScreen_iPhone_12_mini_portrait.png'
import Settings from '../assets/Settings_iPhone_12_mini_portrait.png'
import Modal from '../components/common/Modal'
import i18n from '../i18n/i18n'
import { darkGreyColor, textColor } from '../styles'

export const QrLoginPageModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <IconContainer>
        <StyledIcon size={'1.7em'} color={darkGreyColor} onClick={onClose} />
      </IconContainer>
      <Container>
        <ImageContainer>
          <StyledImage src={Settings} />
          <StyledText>{i18n.t('login.SettingsELearning')}</StyledText>
        </ImageContainer>
        <ImageContainer>
          <StyledImage src={QrScanner} />
          <StyledText>{i18n.t('login.ScanQRCode')}</StyledText>
        </ImageContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 600px;
  width: 800px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  padding-bottom: 0;
`
const StyledIcon = styled(IoMdClose)`
  cursor: pointer;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  user-select: none;
`

const StyledImage = styled.img`
  height: 500px;
`
const StyledText = styled.p`
  font-size: 1em;
  color: ${textColor};
  font-weight: bold;
`
