import React from 'react'
import { FaCheck } from 'react-icons/fa'
import styled, { keyframes } from 'styled-components'

import { tiffanyBlueColor } from '../../styles'

export default function AnimatedCheckMark({
  size = '16px',
  color = tiffanyBlueColor,
}) {
  return (
    <FadeContainer>
      <FaCheck size={size} color={color} />
    </FadeContainer>
  )
}

const FadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`
const FadeContainer = styled.div`
  visibility: ${'visible'};
  animation: ${FadeIn} 1s linear;
  transition: visibility 1s linear;
`
