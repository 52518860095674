export const LOGIN_SUCCESS = 'login_success'
export const LOGIN_FAILED = 'login_failed'
export const LOGIN_PENDING = 'login_pending'

export const LOGOUT = 'logout'

export const QRCODE_FETCH_SUCCESS = 'qrcode_fetch_success'
export const QRCODE_FETCH_FAILED = 'qrcode_fetch_failed'
export const QRCODE_FETCH_LOADING = 'qrcode_fetch_loading'
export const QRCODE_EXPIRED = 'qrcode_expired'
export const QRCODE_SCANNED = 'qrcode_scanned'

export const QRCODE_LOGIN_SUCCESS = 'qrcode_login_success'
