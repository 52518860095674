export const ORGANIZATION_FETCHING = 'organization_fetching'
export const ORGANIZATION_FETCHED = 'organization_fetched'
export const ORGANIZATION_FETCHED_FAILED = 'organization_fetched_failed'

const initialState = {
  isLoading: false,
  organizations: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ORGANIZATION_FETCHING: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ORGANIZATION_FETCHED_FAILED: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case ORGANIZATION_FETCHED: {
      return {
        ...state,
        isLoading: false,
        organizations: {
          ...state.organizations,
          [payload.organizationId]: payload,
        },
      }
    }
    default:
      return state
  }
}
