import 'firebase/compat/auth'

import firebase from 'firebase/compat/app'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'

import { LOGIN } from '../routes'

/**
 * @param {Object} obj - an object
 * @param {string} [obj.path = DASH_QRLOGIN] - path the component will return to if the condition fails.
 * @param {function} obj.condition  -  a function that returns a Promise<boolean> and determines if the user is authorized.
 */
const withAuthorization = ({ condition, path = LOGIN }) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth().onAuthStateChanged(async (user) => {
        const valid = await condition(user)
        if (!valid) {
          this.props.history.push(path)
        }
      })
    }
    render() {
      return this.props.user ? <Component {...this.props} /> : null
    }
  }

  const mapStateToProps = ({ session }) => ({
    user: session.user,
  })
  return compose(connect(mapStateToProps), withRouter)(WithAuthorization)
}
export default withAuthorization
