import 'firebase/compat/auth'

import firebase from 'firebase/compat/app'

export const isPhoneUser = async () => {
  const user = firebase.auth().currentUser
  if (!user) {
    return false
  }
  const token = await user.getIdTokenResult()
  if (token && token.claims && token.claims.phoneUser) {
    return true
  }
  return false
}
