import React from 'react'
import { StyleSheet, Text } from 'react-native'

export const Link = ({ href, style, children }) => (
  <Text
    // always open in new tab
    target="_blank"
    accessibilityRole="link"
    href={href}
    style={[styles.link, style]}
  >
    {children}
  </Text>
)

const styles = StyleSheet.create({
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center',
  },
  link: {
    textDecorationLine: 'underline',
  },
})
