import firebase from 'firebase/compat/app'

import {
  E_LEARNING_MODULE_FOR_ORGANIZATION_RETRIEVED,
  E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION,
} from './ELearningReducer'

export const fetchELearningModulesByOrganizationIdAction = (organizationId) => (
  dispatch
) => {
  if (!organizationId) {
    return
  }

  const ref = firebase
    .database()
    .ref(`organizations/${organizationId}/eLearnings`)

  const refs = {}

  ref.on('child_added', (eLearningIdSnapShot) => {
    const eLearningId = eLearningIdSnapShot.key

    const eLearningRef = firebase.database().ref(`elearnings/${eLearningId}`)
    refs[`elearnings/${eLearningId}`] = eLearningRef

    eLearningRef.on('value', (eLearning) => {
      if (!eLearning.exists()) {
        dispatch({
          type: E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION,
          payload: {
            organizationId,
            eLearningId,
          },
        })

        return
      }

      const { name, url, description } = eLearning.val()

      dispatch({
        type: E_LEARNING_MODULE_FOR_ORGANIZATION_RETRIEVED,
        payload: {
          organizationId,
          eLearningId: eLearning.key,
          name,
          url,
          description,
        },
      })
    })
  })

  ref.on('child_removed', (eLearningIdSnapShot) => {
    dispatch({
      type: E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION,
      payload: {
        organizationId,
        eLearningId: eLearningIdSnapShot.key,
      },
    })

    if (!refs[eLearningIdSnapShot.key]) {
      return
    }

    refs[eLearningIdSnapShot.key].off()
    delete refs[eLearningIdSnapShot.key]
  })
}
