import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'

import { store } from '../Store'
import { ACCOUNT_TEAM_FETCH_SUCCESS } from './TeamsReducer'

export const fetchTeams = () => async (dispatch) => {
  const { user } = store.getState().session

  if (!user) {
    return
  }

  if (!user.teams) {
    Sentry.captureException(
      new Error('Failed to fetch teams, user is not in any team')
    )
    return
  }

  for (const teamId in user.teams) {
    firebase
      .database()
      .ref(`teams/${teamId}`)
      .on('value', (teamSnap) => {
        if (!teamSnap.exists()) {
          return
        }
        dispatch({
          type: ACCOUNT_TEAM_FETCH_SUCCESS,
          payload: teamSnap.val(),
        })
      })
  }
}
