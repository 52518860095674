let localConfig

export function getConfig() {
  return localConfig
}

export function getCloudFunctionsUrl() {
  return localConfig.cloudFunctionsUrl
}

export function getDatabaseUrl() {
  return localConfig.databaseUrl
}

export function getRegion() {
  return localConfig.region
}

export function getUpdateUrl() {
  return localConfig.updateUrl
}

export function setConfig(serverConfig) {
  localConfig = serverConfig
}
