export const E_LEARNING_MODULE_FOR_ORGANIZATION_RETRIEVED =
  'E_LEANING_FOR_ORG_OK'
export const E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION =
  'E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION'

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case E_LEARNING_MODULE_NOT_FOUND_FOR_ORGANIZATION: {
      const { organizationId, eLearningId } = payload
      if (!state[organizationId]) {
        return state
      }

      delete state[organizationId][eLearningId]

      return state
    }
    case E_LEARNING_MODULE_FOR_ORGANIZATION_RETRIEVED: {
      return {
        ...state,
        [payload.organizationId]: {
          ...state[payload.organizationId],
          [payload.eLearningId]: {
            eLearningId: payload.eLearningId,
            name: payload.name,
            url: payload.url,
            description: payload.description,
          },
        },
      }
    }
    default:
      return state
  }
}
