import { applyMiddleware, combineReducers, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'

import session from './auth/LoginReducer'
import eLearningModules from './elearning/ELearningReducer'
import organizations from './organizations/organizationReducer'
import teams from './teams/TeamsReducer'

export const store = createStore(
  combineReducers({
    session,
    teams,
    eLearningModules,
    organizations,
  }),
  {},
  applyMiddleware(ReduxThunk)
)
