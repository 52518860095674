import React from 'react'
import { Text, View } from 'react-native'
import { withRouter } from 'react-router-dom'

class NotFoundPage extends React.Component {
  render() {
    return (
      <View
        data-testid="page_not_found"
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <Text style={{ fontSize: 30, textAlign: 'center' }}>Thats a 404!</Text>
      </View>
    )
  }
}

export default withRouter(NotFoundPage)
