import {
  Box,
  CardActions,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { FaEarlybirds } from 'react-icons/fa'
import { connect } from 'react-redux'
import ShowMoreText from 'react-show-more-text'
import { compose } from 'redux'

import { isPhoneUser } from '../auth/authConditions'
import withAuthorization from '../auth/withAuthorization'
import { Card, EmptySection } from '../components/common'
import Button from '../components/common/Button'
import i18n from '../i18n/i18n'
import { logEvent } from '../monitoring/monitoringClient'
import { fetchOrganizationsForUserAction } from '../organizations/organizationActions'
import { LOGIN } from '../routes'
import { fetchELearningModulesByOrganizationIdAction } from './ELearningActions'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
  },
  actions: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

function RenderELeaningPage({
  user,
  organizations,
  isLoading,
  eLearningModulesPerOrganization,
  fetchOrganizationsForUser,
  fetchELearningModulesByOrganizationId,
}) {
  const classes = useStyles()
  const [eLearningModules, setELearningModules] = useState({})

  useEffect(() => {
    fetchOrganizationsForUser(user)
  }, [user, fetchOrganizationsForUser])

  useEffect(() => {
    Object.keys(organizations).map(fetchELearningModulesByOrganizationId)
  }, [organizations, fetchELearningModulesByOrganizationId])

  useEffect(() => {
    setELearningModules(
      Object.values(eLearningModulesPerOrganization).reduce(
        (modules, organizationModules) => {
          return { ...modules, ...organizationModules }
        },
        {}
      )
    )
  }, [eLearningModulesPerOrganization])

  if (isLoading) {
    return <CircularProgress size={100} />
  }

  if (!Object.values(eLearningModules).length) {
    return (
      <Grid container item xs={12} justifyContent="center">
        <Card title={`${i18n.t('ELearning.ELearning')}`}>
          <EmptySection
            Icon={FaEarlybirds}
            text={i18n.t('ELearning.NotAvailable')}
          />
        </Card>
      </Grid>
    )
  }

  return Object.values(eLearningModules).map((module) => {
    const startELearning = (eLearning) => () => {
      logEvent('E-Learning opened', {
        eLearningId: eLearning.eLearningId,
      })

      const url =
        eLearning.url.substr(0, 4) === 'http'
          ? eLearning.url
          : `https://${eLearning.url}`

      window.open(url, '_blank')
    }

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={module.eLearningId}
        className={classes.card}
      >
        <Card title={module.name}>
          <Grid container direction="column" justifyContent="space-between">
            <Grid item xs={12}>
              <Box minHeight={65}>
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more={<ExpandMore />}
                  less={<ExpandLess />}
                  expanded={false}
                >
                  {module.description}
                </ShowMoreText>
              </Box>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <CardActions>
                <Button
                  buttonText={'Start'}
                  onPress={startELearning(module)}
                  color="primary"
                  size="small"
                  variant="text"
                />
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  })
}

const mapStateToProps = ({
  session: { user },
  eLearningModules,
  organizations: { organizations, isLoading },
}) => ({
  user,
  eLearningModulesPerOrganization: eLearningModules,
  organizations,
  isLoading,
})

export default compose(
  withAuthorization({ condition: isPhoneUser, path: LOGIN }),
  connect(mapStateToProps, {
    fetchOrganizationsForUser: fetchOrganizationsForUserAction,
    fetchELearningModulesByOrganizationId: fetchELearningModulesByOrganizationIdAction,
  })
)(RenderELeaningPage)
