import 'firebase/compat/functions'

import firebase from 'firebase/compat/app'

import { getRegion } from '../constants/serverVariables'

export const firebaseFunction = () => {
  const region = getRegion()
  return firebase.app().functions(region)
}
