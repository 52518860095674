import { Paper, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px',
  },
}))

export default ({ testId, title, children }) => {
  const classes = useStyles()

  return (
    <Paper data-testid={testId} className={classes.card}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  )
}
