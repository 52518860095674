import { createGlobalStyle } from 'styled-components'

import { backgroundColor, textColor } from './'

export default createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    box-sizing: border-box;
    overflow: hidden;
    color: ${textColor};
    background-color: ${backgroundColor};
  }`
