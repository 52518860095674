import firebase from 'firebase/compat/app'

export const getOrganizationById = async (organizationId) => {
  if (!organizationId) {
    return
  }

  const snapshot = await firebase
    .database()
    .ref(`organizations/${organizationId}/name`)
    .once('value')

  return snapshot.exists()
    ? { organizationId, name: snapshot.val() }
    : undefined
}
