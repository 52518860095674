import { AppBar, Grid, Tab, Tabs, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import ELearningPage from '../elearning/ELearningPage'
import i18n from '../i18n/i18n'
import { lightGrey, orangeColor, tiffanyBlueColor } from '../styles'
import TeamsPage from '../teams/TeamsPage'
import LogoutButton from './common/LogoutButton'
import NavLogo from './common/NavLogo'
import TabPanel from './Tabs/TabPanel'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: tiffanyBlueColor,
    color: lightGrey,
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  tabSelected: {
    backgroundColor: 'white',
  },
}))

export default () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Grid container spacing={3} alignItems="center">
          <Grid
            container
            item
            xs={1}
            justifyContent="center"
            alignItems="center"
            style={{ paddingRight: 0 }}
          >
            <NavLogo />
          </Grid>
          <Grid item xs={9}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: orangeColor,
                },
              }}
            >
              <Tab label={i18n.t('navigation.Teams')} />
              <Tab label={i18n.t('ELearning.ELearning')} />
            </Tabs>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end" spacing={3}>
            <LogoutButton />
          </Grid>
        </Grid>
      </AppBar>
      <main className={classes.content}>
        <TabPanel value={value} index={0}>
          <TeamsPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ELearningPage />
        </TabPanel>
      </main>
    </div>
  )
}
