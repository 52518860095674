import 'firebase/compat/auth'

import firebase from 'firebase/compat/app'
import QRCode from 'qrcode.react'
import React from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components'

import logo from '../assets/Logo2020_White.png'
import { requestQrCode } from '../auth/LoginActions'
import Button from '../components/common/Button'
import i18n from '../i18n/i18n'
import { logEvent } from '../monitoring/monitoringClient'
import { HOME } from '../routes'
import { tiffanyBlueColor } from '../styles'
import { QrLoginPageModal } from './QrLoginPageModal'

class QrLoginPage extends React.Component {
  state = {
    flexDirection: 'row',
    isModalOpen: false,
  }

  async componentDidMount() {
    // listen to resize
    this.updateFlexDirection()
    window.addEventListener('resize', this.updateFlexDirection)

    // check if already logged in
    const user = await firebase.auth().currentUser
    if (user) {
      const token = await user.getIdTokenResult()
      if (token && token.claims && token.claims.phoneUser) {
        this.props.history.push(HOME)
        return
      }
    }
    await this.props.requestQrCode()
  }

  updateFlexDirection = () => {
    const flexDirection = window.innerWidth < 1100 ? 'column' : 'row'
    if (this.state.flexDirection !== flexDirection) {
      this.setState(() => ({ flexDirection }))
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.qrLoginSuccess &&
      this.props.qrLoginSuccess !== prevProps.qrLoginSuccess
    ) {
      this.props.history.push(HOME)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateFlexDirection)
  }

  onPressGenerateNewCode = () => {
    logEvent('generate_qr_code_button_clicked')

    this.props.requestQrCode()
  }

  renderQrCode = () => {
    if (this.props.isQrCodeLoading || this.props.isQrCodeScanned) {
      return <ActivityIndicator color={'white'} size="large" />
    }

    if (!this.props.qrCode && !this.props.isQrCodeLoading) {
      return (
        <Button
          color="inherit"
          buttonText={i18n.t('login.GenerateNewCode')}
          onPress={this.onPressGenerateNewCode}
          testId={'generate-qr-code-button'}
        />
      )
    }

    // Size is 13 rem. Same as qrcode size.
    return (
      <QRCode
        data-testid="qr-code"
        value={this.props.qrCode}
        size={13 * 16}
        renderAs="svg"
      />
    )
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false })
  }
  onShowModal = () => {
    logEvent('qr_login_tooltip_clicked')
    this.setState({ isModalOpen: true })
  }

  render() {
    return (
      <View style={styles.container}>
        <QrLoginPageModal
          isOpen={this.state.isModalOpen}
          onClose={this.onCloseModal}
        />
        {<Image source={logo} style={styles.logo} />}
        <ScrollView
          contentContainerStyle={styles.innerContentContainer}
          style={styles.innerContainer}
        >
          <View
            style={{
              flexDirection: this.state.flexDirection || 'row',
              width: '100%',
            }}
          >
            <HowToContainer>
              <QrContainer data-testid={'qr-code-container'}>
                {this.renderQrCode()}
              </QrContainer>

              <Title>{i18n.t('login.LoginInToTeamTelefoonWeb')}</Title>
              <Circle>1</Circle>
              <HowToText>{i18n.t('login.OpenTheTeamTelefoonApp')}</HowToText>
              <Circle>2</Circle>

              <HowToText>
                {i18n.t('login.TapSettingsAndSelectELearning')}
                <StyledIcon onClick={this.onShowModal} />
              </HowToText>

              <Circle>3</Circle>
              <HowToText>{i18n.t('login.PointYourCameraAtTheQR')}</HowToText>
            </HowToContainer>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const StyledIcon = styled(FaRegQuestionCircle)`
  cursor: pointer;
`

const Circle = ({ children }) => (
  <CircleContainer>
    <CircleText>{children}</CircleText>
  </CircleContainer>
)

const HowToContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  column-gap: 0.5em;
`
const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: white 3px solid;
  border-radius: 50%;
  align-self: center;
  justify-self: end;
  grid-column: 1;
`

const HowToText = styled.span`
  font-family: sans-serif;
  font-size: 1.25rem;
  color: white;
  align-self: center;
  grid-column-start: 2;
`
const Title = styled.h1`
  font-family: sans-serif;
  font-size: 2.25rem;
  color: white;
  grid-column-start: 2;
`
const CircleText = styled.span`
  fontsize: 1.5em;
  color: white;
  font-weight: bold;
`
const QrContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  justify-self: center;
  grid-column: 3;
  grid-row: 2/5;
  color: white;
`

const styles = StyleSheet.create({
  container: {
    backgroundColor: tiffanyBlueColor,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem',
  },
  innerContainer: {
    flex: 1,
  },
  innerContentContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  logo: {
    width: '260px',
    height: '55px',
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem',
    backgroundColor: tiffanyBlueColor,
    tintColor: 'white',
  },
  howToTextContainer: {
    flexDirection: 'row',
  },
})

const mapStateToProps = (state) => {
  return {
    qrCode: state.session.qrCode,
    isQrCodeLoading: state.session.isQrCodeLoading,
    isQrCodeScanned: state.session.isQrCodeScanned,
    qrLoginSuccess: state.session.qrLoginSuccess,
  }
}

export default connect(mapStateToProps, { requestQrCode })(QrLoginPage)
