import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { setAuthStateListener } from '../auth/LoginActions'
import Layout from '../components/Layout'
import NotFoundPage from '../components/NotFoundPage'
import QrLoginPage from '../login/QrLoginPage'
import { LOGIN } from './index'

class Router extends React.Component {
  componentDidMount() {
    this.props.setAuthStateListener()
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Layout} />
          <Route exact path={LOGIN} component={QrLoginPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    )
  }
}
export default connect(null, { setAuthStateListener })(Router)
