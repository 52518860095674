import { Button } from '@material-ui/core'
import React from 'react'

import { doLogout } from '../../auth/LoginActions'
import i18n from '../../i18n/i18n'

export default function LogoutButton() {
  return (
    <Button
      data-testid="logout_button"
      onClick={doLogout}
      color="inherit"
      style={{ backgroundColor: 'transparent' }}
    >
      {i18n.t('LogOut')}
    </Button>
  )
}
