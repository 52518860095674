import firebase from 'firebase/compat/app'

export const getAllTeamsForUser = async (user) => {
  if (!user) {
    return []
  }

  const teams = await Promise.all(
    Object.keys(user.teams || {}).map(async (teamId) => {
      const teamSnapShot = await firebase
        .database()
        .ref(`teams/${teamId}`)
        .once('value')

      return teamSnapShot.exists() ? teamSnapShot.val() : {}
    })
  )

  // filter out empty teams
  return teams.filter(Boolean)
}
