import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import logo from '../../assets/logo-teamtelefoon.svg'
import { backgroundColorDark, tiffanyBlueColor } from '../../styles'

export default class NavLogo extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <Image source={logo} style={styles.image} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: backgroundColorDark,
    width: '3rem',
    height: '3rem',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    tintColor: tiffanyBlueColor,
  },
})
