import React from 'react'
import ReactModal from 'react-modal'

export default function Modal({ isOpen, handleCloseModal, children }) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          position: 'relative',
          padding: 'none',
          top: 0,
          border: 'none',
        },
      }}
    >
      {children}
    </ReactModal>
  )
}
