import 'react-dates/initialize'

import './styles/styles.css'

import { MuiThemeProvider, createTheme } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import React from 'react'
import { Provider } from 'react-redux'

import Router from './routes/Router'
import { store } from './Store'
import { buttonPrimary } from './styles'
import GlobalStyle from './styles/globalStyle'

const theme = createTheme({
  palette: {
    primary: {
      main: buttonPrimary,
    },
  },
})

class App extends React.Component {
  initializeFirebase = (firebaseConfig) => {
    firebase.initializeApp(firebaseConfig)
  }

  constructor(props) {
    super(props)
    this.initializeFirebase(props.firebaseConfig)
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <GlobalStyle />
          <Router />
        </Provider>
      </MuiThemeProvider>
    )
  }
}
export default App
