import 'firebase/compat/analytics'

import firebase from 'firebase/compat/app'

const envConfig = require('../config/config.json')

export const logEvent = (eventName, params) => {
  firebase.analytics().logEvent(eventName, params)

  if (envConfig.env === 'test') {
    console.log(eventName, params)
  }
}
