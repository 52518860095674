/**
 * colors
 */
// TeamTelefoon Color Names
export const tiffanyBlueColor = '#1DC8B6'
export const eggshellColor = '#F5E6D8'
export const orangeColor = '#FF9800'

// Other Color Names
export const persianRedColor = '#C83232'

// Fixed colors
export const alarmRedColor = persianRedColor
export const alarmRedColorDark = '#ba2d2d'

// Style Guide Colors

//Primary colors
export const lightSeaGreenColor = '#1DC7B5'
export const iceColdGreenColorLight = '#9FEFDB'

// Grey Colors
export const charcoalColor = '#414141'
export const darkGreyColor = '#ABABAB'
export const whisperGreyColor = '#E4E4E4'
export const snowGreyColor = '#F9F9F9'

// secondary Colors
export const flamingoOrangeColor = '#EE5F4B'
export const atomicTangerineOrangeColorLight = '#FF906D'

// Color mapping TeamTelefoon

export const primaryGreenColor = lightSeaGreenColor
export const primaryGreenColorLight = iceColdGreenColorLight

export const primaryOrangeColor = flamingoOrangeColor

export const backgroundColor = snowGreyColor
export const backgroundColorDark = whisperGreyColor

export const headerColor = darkGreyColor

export const font = 'HelveticaNeue, Arial, sans-serif'
export const textColor = charcoalColor
export const textPlaceholderColor = '#aab7c4'
export const lightGrey = '#efefef'
export const lighterGrey = snowGreyColor

export const formValidGreen = '#d3e8d3'

export const buttonPrimary = lightSeaGreenColor
